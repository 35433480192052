import { Pharmacy } from '@core/interfaces/pharmacy.interface';
import { TranslateService } from '@ngx-translate/core';
import { formatSchedule } from './data-helper';

export function transformPharmacy(pharmacy: Pharmacy, translateService: TranslateService): any {
  const timeWork = formatSchedule(pharmacy.pharmacyTimeToWork!, translateService);
  return {
    id: pharmacy.id,
    name: pharmacy.pharmacyName,
    city: pharmacy.pharmacyLocalizedAddress,
    adress: pharmacy.pharmacyAddress || '',
    isPharmacyWorking: pharmacy.pharmacyWorkingNow,
    subInfo: [
      {
        workTime: timeWork,
        coordinates: pharmacy.geoPoint ? `${pharmacy.geoPoint[0]} ${pharmacy.geoPoint[1]}` : 'Не знайдено',
        owner: pharmacy.pharmacyEmail,
      },
    ],
  };
}
